<template>
  <div class="hold-transition">
    <div class="wrapper">
      <div class="content-wrapper">
        <section class="content-header">
          <div class="container-fluid">
            <div class="row">
              <div class="col-sm-6">
                <h5 class="mb-0">Contratos</h5>
              </div>
              <div class="col-sm-6">
                <ol class="breadcrumb float-sm-right">
                  <li class="breadcrumb-item">
                    <router-link to="/">Home</router-link>
                  </li>
                  <li class="breadcrumb-item active">Hidrocarburos</li>
                  <li class="breadcrumb-item active">Contratos</li>
                </ol>
              </div>
            </div>
          </div>
        </section>
        <section class="content">
          <div class="container-fluid">
            <div class="card">
              <div class="card-header pt-2 pb-2">
                <div class="row">
                  <div class="col-md-11"></div>
                  <div class="col-md-1">
                    <div class="btn-group float-right">
                      <button
                        type="button"
                        class="btn btn-primary"
                        @click="create()"
                        v-if="
                          $store.getters.can('hidrocarburos.contratos.create')
                        "
                      >
                        <i class="fas fa-plus"></i>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <!-- /.card-header -->
              <div class="card-body p-0">
                <table
                  id="contratos"
                  class="table table-bordered table-striped table-hover table-sm"
                >
                  <thead>
                    <tr>
                      <th>
                        Contrato
                        <input
                          type="text"
                          class="form-control form-control-sm"
                          v-model="filtros.no_contrato"
                          @keyup="getIndex()"
                        />
                      </th>
                      <th>
                        <label> Empresa </label>
                        <v-select
                          :class="
                            $store.getters.getDarkMode ? 'dark-vselect' : ''
                          "
                          v-model="slct_empresa"
                          placeholder="Empresas"
                          label="razon_social"
                          class="form-control form-control-sm p-0"
                          :options="empresas"
                          :filterable="false"
                          @search="buscarEmpresas"
                          @input="getIndex()"
                        ></v-select>
                      </th>
                      <th>Observaciones</th>
                      <th style="width: 150px;">
                        Estado
                        <select
                          class="form-control form-control-sm"
                          v-model="filtros.estado"
                          @change="getIndex()"
                        >
                          <option value="">Seleccione...</option>
                          <option
                            v-for="estado in listasForms.estados"
                            :key="estado.numeracion"
                            :value="estado.numeracion"
                          >
                            {{ estado.descripcion }}
                          </option>
                        </select>
                      </th>
                      <th>Accion</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="contrato in contratos.data" :key="contrato.id">
                      <td>{{ contrato.no_contrato }}</td>
                      <td>{{ contrato.empresa.razon_social }}</td>
                      <td>{{ contrato.observaciones }}</td>
                      <td class="text-center">
                        <span
                          class="badge"
                          v-bind:class="[
                            contrato.estado == 1
                              ? 'badge-warning'
                              : 'badge-success',
                          ]"
                        >
                          {{ contrato.estado == 1 ? "Borrador" : "Definitivo" }}
                        </span>
                      </td>
                      <td style="width: 50px">
                        <div class="btn-group float-right">
                          <button
                            type="button"
                            class="btn btn-sm bg-navy"
                            v-if="
                              $store.getters.can('hidrocarburos.contratos.show')
                            "
                            @click="edit(contrato)"
                          >
                            <i class="fas fa-edit"></i>
                          </button>
                          <button
                            type="button"
                            class="btn btn-sm btn-danger"
                            v-if="
                              $store.getters.can(
                                'hidrocarburos.contratos.delete'
                              )
                            "
                            @click="destroy(contrato.id)"
                          >
                            <i class="fas fa-trash-alt"></i>
                          </button>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="card-footer">
                <div class="float-left" v-if="contratos.total">
                  <p>
                    Mostrando del <b>{{ contratos.from }}</b> al
                    <b>{{ contratos.to }}</b> de un total:
                    <b>{{ contratos.total }}</b> Registros
                  </p>
                </div>
                <div class="float-left" v-else>
                  <p>
                    <span class="badge badge-danger">
                      No hay registros para mostrar
                    </span>
                  </p>
                </div>
                <pagination
                  :data="contratos"
                  @pagination-change-page="getIndex"
                  :limit="5"
                  align="right"
                ></pagination>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  </div>
</template>

<script>
import vSelect from "vue-select";
import axios from "axios";
import pagination from "laravel-vue-pagination";

export default {
  name: "ContratoIndex",
  components: {
    pagination,
    vSelect,
  },
  data() {
    return {
      file: "",
      filtros: {
        no_contrato: null,
        empresa_id: null,
        estado: null,
      },
      contratos: {},
      empresas: [],
      slct_empresa: [],
      listasForms: {
        estados: [],
      },
    };
  },
  methods: {
    getIndex(page = 1) {
      this.filtros.empresa_id = null;
      if (this.slct_empresa) {
        this.filtros.empresa_id = this.slct_empresa.id;
      }
      axios
        .get("/api/hidrocarburos/contratos?page=" + page, {
          params: this.filtros,
        })
        .then((response) => {
          this.contratos = response.data;
        });
    },

    getEstados() {
      axios.get("/api/lista/28").then((response) => {
        this.listasForms.estados = response.data;
      });
    },

    destroy(idContrato) {
      this.$swal({
        title: "Esta seguro de eliminar esta Contrato?",
        text: "Los cambios no se pueden revertir!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Si, Eliminar!",
      }).then((result) => {
        if (result.value) {
          axios
            .delete("/api/hidrocarburos/contratos/" + idContrato)
            .then(() => {
              this.getIndex();
              this.$swal({
                icon: "success",
                title: "Se elimino la Contrato exitosamente...",
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 4000,
                timerProgressBar: true,
              });
            });
        }
      });
    },

    buscarEmpresas(search, loading) {
      if (search != "") {
        let me = this;
        loading(true);
        var url = "api/admin/empresas/lista?razon=" + search;
        axios
          .get(url)
          .then(function(response) {
            let respuesta = response.data;
            me.empresas = respuesta;
            loading(false);
          })
          .catch(function(error) {
            this.$swal({
              icon: "error",
              title: "Ocurrio un error" + error,
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
          });
      }
    },

    create() {
      return this.$router.push({
        name: "/Hidrocarburos/ContratoForm",
        params: { accion: "Crear", id: null },
      });
    },

    edit(contrato) {
      return this.$router.push({
        name: "/Hidrocarburos/ContratoForm",
        params: { accion: "Editar", data_edit: contrato, id: contrato.id },
      });
    },

    getMsg() {
      if (this.$route.params.msg) {
        this.$swal({
          icon: "success",
          title: this.$route.params.msg,
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 4000,
          timerProgressBar: true,
        });
      }
    },
  },
  mounted() {
    this.getIndex();
    this.getEstados();
    this.getMsg();
  },
};
</script>
